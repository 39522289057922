import { Box, Button, Flex, Table, Tbody, Td, Text, Th, Thead, Tr, useTheme } from '@chakra-ui/react';
import {
    ColumnDef,
    flexRender,
    getCoreRowModel,
    getSortedRowModel,
    SortingState,
    useReactTable,
    VisibilityState,
} from '@tanstack/react-table';
import { useState } from 'react';

type DataTableProps<Data extends object> = {
    isDesktopView: boolean;
    data: Data[];
    columns: ColumnDef<Data, any>[];
    emptyText: string;
    sortBy: SortingState;
    columnVisibility?: VisibilityState;
    paginationData?: {
        isEnabled: boolean;
        currentPage?: number;
        prevPage?: () => void;
        nextPage?: () => void;
        limitPerPage?: number;
    };
    headerActions?: Record<string, { onClick: () => void }>;
};

export default function TableComponent<Data extends object>({
    columns,
    data,
    emptyText,
    isDesktopView,
    sortBy,
    columnVisibility,
    paginationData = { isEnabled: false },
    headerActions,
}: DataTableProps<Data>) {
    const [sorting, setSorting] = useState<SortingState>(sortBy);
    const table = useReactTable({
        columns,
        data,
        getCoreRowModel: getCoreRowModel(),
        onSortingChange: setSorting,
        getSortedRowModel: getSortedRowModel(),
        state: {
            sorting,
            columnVisibility,
        },
    });
    const { colors } = useTheme();

    return (
        <>
            {isDesktopView ? (
                <>
                    <Table border-collapse="separate" border-spacing={0} size="sm" width="100%" whiteSpace="nowrap">
                        <Thead>
                            {table.getHeaderGroups().map((headerGroup) => (
                                <Tr key={headerGroup.id} whiteSpace="nowrap">
                                    {headerGroup.headers.map((header, index) => {
                                        const meta: any = header.column.columnDef.meta;
                                        const extraData =
                                            headerActions && headerActions[header.id]
                                                ? headerActions[header.id]
                                                : undefined;
                                        return (
                                            <Th
                                                onClick={
                                                    extraData
                                                        ? extraData.onClick
                                                        : header.column.getToggleSortingHandler()
                                                }
                                                style={meta?.style}
                                                key={header.id}
                                                isNumeric={meta?.isNumeric}
                                                position="sticky"
                                                top={0}
                                                bgColor="card.100"
                                                zIndex={2}
                                                color="text.100"
                                                userSelect="none"
                                                p="0.5rem"
                                                _hover={{ color: 'text.200', transition: 'all 250ms ease-out' }}
                                                cursor={extraData ? 'pointer' : undefined}
                                                // w={header.collapse ? '0.0000000001%' : '1%'}
                                                _before={{
                                                    content: '""',
                                                    position: 'absolute',
                                                    left: 0,
                                                    width: '100%',
                                                    top: '-1px',
                                                }}
                                                _after={{
                                                    content: '""',
                                                    position: 'absolute',
                                                    left: 0,
                                                    width: '100%',
                                                    bottom: '-0.5px',
                                                    borderBottom: `1px solid ${colors.border[100]}`,
                                                }}
                                                borderBottom="none"
                                            >
                                                {flexRender(header.column.columnDef.header, header.getContext())}
                                            </Th>
                                        );
                                    })}
                                </Tr>
                            ))}
                        </Thead>

                        <Tbody maxH="100px" overflowY="scroll">
                            {!table.getRowModel().rows.length ? (
                                <Tr
                                    bgColor="card.100"
                                    my="0.25rem"
                                    borderTop="none"
                                    zIndex={2}
                                    _before={{
                                        borderTop: '1px solid rgba(255, 255, 255, 0.05)',
                                    }}
                                >
                                    <Td border="none" color="text.200" pl="0.5rem">
                                        {emptyText}
                                    </Td>
                                </Tr>
                            ) : (
                                table.getRowModel().rows.map((row) => {
                                    return (
                                        <Tr
                                            key={row.id}
                                            borderTop={
                                                row.index !== 0 ? '1px solid rgba(255, 255, 255, 0.05)' : undefined
                                            }
                                            bgColor="card.100"
                                            alignItems="center"
                                            h="3rem" //prevents 'jumping' column heights between different tables
                                        >
                                            {row.getVisibleCells().map((cell) => {
                                                const meta: any = cell.column.columnDef.meta;
                                                return (
                                                    <Td
                                                        style={meta?.style}
                                                        key={cell.id}
                                                        isNumeric={meta?.isNumeric}
                                                        border="none"
                                                        color="text.200"
                                                        fontSize={isDesktopView ? '1rem' : 'xs'}
                                                        px="0.5rem"
                                                        // w={cell.column.collapse ? '0.0000000001%' : '1%'}
                                                    >
                                                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                                    </Td>
                                                );
                                            })}
                                        </Tr>
                                    );
                                })
                            )}
                        </Tbody>
                    </Table>
                    {paginationData.isEnabled && (
                        <Flex justifyContent="space-between" mb="0.5rem" mt="0.5rem">
                            <Button
                                isDisabled={paginationData.currentPage ? paginationData.currentPage <= 1 : true}
                                onClick={paginationData.prevPage}
                                size={isDesktopView ? 'xs' : 'sm'}
                                fontSize={isDesktopView ? '0.8rem' : 'xs'}
                            >
                                Previous Page
                            </Button>
                            <Text mt="0.5rem" fontSize={isDesktopView ? '0.8rem' : 'xs'}>
                                {paginationData.currentPage ?? 1}
                            </Text>
                            <Button
                                isDisabled={
                                    data === undefined ||
                                    data.length === 0 ||
                                    data.length < (paginationData.limitPerPage || 5)
                                }
                                onClick={paginationData.nextPage}
                                fontSize={isDesktopView ? '0.8rem' : 'xs'}
                                size={isDesktopView ? 'xs' : 'sm'}
                            >
                                Next Page
                            </Button>
                        </Flex>
                    )}
                </>
            ) : (
                <>
                    {!table.getRowModel().rows.length ? (
                        <Box m="0.5rem" border="none">
                            <Text color="text.200" fontSize="0.75rem">
                                {emptyText}
                            </Text>
                        </Box>
                    ) : (
                        <Table border-collapse="collapse" width="100%" size="sm" whiteSpace="nowrap">
                            <Thead>
                                {table.getHeaderGroups().map((headerGroup) => (
                                    <Tr key={headerGroup.id} position="sticky" top={0} whiteSpace="nowrap">
                                        {headerGroup.headers.map((header) => {
                                            const meta: any = header.column.columnDef.meta;
                                            return (
                                                <Th
                                                    key={header.id}
                                                    isNumeric={meta?.isNumeric}
                                                    style={meta?.style}
                                                    position="sticky"
                                                    top={0}
                                                    py="0.75rem"
                                                    bgColor="card.100"
                                                    border="none"
                                                    zIndex="2"
                                                    color="text.100"
                                                    userSelect="none"
                                                    whiteSpace="nowrap"
                                                    _hover={{
                                                        color: 'text.200',
                                                        transition: 'all 250ms ease-out',
                                                    }}
                                                >
                                                    {flexRender(header.column.columnDef.header, header.getContext())}
                                                </Th>
                                            );
                                        })}
                                    </Tr>
                                ))}
                            </Thead>
                            <Tbody maxH="100px" overflowY="scroll">
                                {!table.getRowModel().rows.length ? (
                                    <Tr bgColor="card.100" my="0.25rem" border="none">
                                        <Td border="none" color="text.200">
                                            {emptyText}
                                        </Td>
                                    </Tr>
                                ) : (
                                    table.getRowModel().rows.map((row) => {
                                        return (
                                            <Tr
                                                key={row.id}
                                                bgColor="card.100"
                                                borderTop="1px solid rgba(255, 255, 255, 0.05)"
                                                alignItems="center"
                                                h="3rem" //prevents 'jumping' column heights between different tables
                                            >
                                                {row.getVisibleCells().map((cell) => {
                                                    const meta: any = cell.column.columnDef.meta;
                                                    return (
                                                        <Td
                                                            style={meta?.style}
                                                            key={cell.id}
                                                            isNumeric={meta?.isNumeric}
                                                            border="none"
                                                            color="text.200"
                                                            fontSize={isDesktopView ? '1rem' : 'xs'}
                                                        >
                                                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                                        </Td>
                                                    );
                                                })}
                                            </Tr>
                                        );
                                    })
                                )}
                            </Tbody>
                        </Table>
                    )}
                </>
            )}
        </>
    );
}
