import { Box, Flex, Heading, Text } from '@chakra-ui/react';
import { TopTradersResponseItem } from '@derivadex/types';
import LeaderboardTable from 'components/Leadboard/LeaderboardTable';
import { useScreenSize } from 'hooks/useScreenSize';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
    getLeaderboardData,
    getLeaderboardDataCurrentPage,
    getLeaderboardInitalLoadFlag,
    getLeaderboardOrdering,
    getLeaderboardTraderOrdering,
    getLoggedInTraderData,
} from 'store/leaderboard/selectors';
import { FETCH_LEADERBOARD_DATA } from 'store/leaderboard/slice';
import { FetchDataState } from 'store/requestUtils';
import { getEthAddress } from 'store/web3/selectors';

import Layout from '../components/Layout';

export default function Leaderboard() {
    const dispatch = useDispatch();
    const isDesktopView = useScreenSize();
    const traderOrdering = useSelector(getLeaderboardTraderOrdering);
    const ordering = useSelector(getLeaderboardOrdering);
    const traderAddress = useSelector(getEthAddress);
    const { t } = useTranslation();

    const traderData: TopTradersResponseItem | null = useSelector(getLoggedInTraderData);
    const leaderboardData: TopTradersResponseItem[] | undefined = useSelector(getLeaderboardData);
    const currentPage: number = useSelector(getLeaderboardDataCurrentPage);
    const initialLoadFlag = useSelector(getLeaderboardInitalLoadFlag);
    // get data for the current trader address
    useEffect(() => {
        if (!initialLoadFlag) {
            return;
        }
        dispatch(
            FETCH_LEADERBOARD_DATA({
                orderBy: traderOrdering,
                order: ordering,
                page: FetchDataState.NextPage,
                traderFilter: traderAddress,
            }),
        );
    }, [traderAddress]);

    const nextPage = () => {
        dispatch(
            FETCH_LEADERBOARD_DATA({
                orderBy: traderOrdering,
                order: ordering,
                page: FetchDataState.NextPage,
                traderFilter: traderAddress,
            }),
        );
    };

    const prevPage = () => {
        dispatch(
            FETCH_LEADERBOARD_DATA({
                orderBy: traderOrdering,
                order: ordering,
                page: FetchDataState.PrevPage,
                traderFilter: traderAddress,
            }),
        );
    };
    return (
        <Layout isDesktopView={isDesktopView}>
            {isDesktopView ? (
                <Flex direction={'column'} py="3rem" px="5rem">
                    <Heading fontSize={{ base: '2.2vh', lg: '2.7vh' }}>Leaderboard</Heading>
                    <Box p="0.5rem" pt="3rem" width={'90%'} height={'80%'}>
                        <LeaderboardTable
                            isDesktopView={true}
                            data={traderData ? [traderData, ...leaderboardData] : leaderboardData}
                            paginationData={{ isEnabled: true, currentPage, nextPage, prevPage, limitPerPage: 10 }}
                            traderAddress={traderData ? traderAddress : undefined}
                        ></LeaderboardTable>
                    </Box>
                    <Text fontSize={'xs'}>*{t('leaderboardUpdateText')}</Text>
                </Flex>
            ) : (
                <Flex mt="0.5rem" mb="0.5rem" direction="column" gap="0.5rem" h="100%" w="100%">
                    <Heading fontSize={{ base: '2.2vh', lg: '2.7vh' }}>Leaderboard</Heading>
                    <Box
                        p="0.5rem"
                        overflowY="scroll"
                        sx={{
                            '::-webkit-scrollbar': {
                                display: 'none',
                            },
                            scrollbarWidth: 'none',
                        }}
                    >
                        <LeaderboardTable
                            isDesktopView={true}
                            data={traderData ? [traderData, ...leaderboardData] : leaderboardData}
                            paginationData={{ isEnabled: true, currentPage, nextPage, prevPage, limitPerPage: 10 }}
                            traderAddress={traderData ? traderAddress : undefined}
                        ></LeaderboardTable>
                    </Box>
                    <Text fontSize={'xs'}>*{t('leaderboardUpdateText')}</Text>
                </Flex>
            )}
        </Layout>
    );
}
