import { EpochConfig, OperatorConfig, RuntimeConfig } from '@derivadex/types';
import { AppState } from 'store/slices';

export function getRuntimeConfig(state: AppState): RuntimeConfig {
    return state.config.runtime;
}

export function isServerLoadComplete(state: AppState): boolean {
    return state.config.isServerLoadComplete;
}

export function getOperatorConfig(state: AppState): OperatorConfig | undefined {
    return state.config.operator;
}

export function getKycConfig(state: AppState): OperatorConfig | undefined {
    return state.config.kyc;
}

export function getEpochConfig(state: AppState): EpochConfig | undefined {
    return state.config.epoch;
}

export function getPortfolioPageEnabled(state: AppState) {
    return state.config.runtime.PORTFOLIO_PAGE_ENABLED === 'true';
}

export function getVaultsPageEnabled(state: AppState) {
    return state.config.runtime.VAULTS_PAGE_ENABLED === 'true';
}

export function getLeaderboardPageEnabled(state: AppState) {
    return state.config.runtime.LEADERBOARD_PAGE_ENABLED === 'true';
}

export const getStatsApiUrl = (state: AppState) =>
    state.config.runtime.STATS_API_URL != undefined && state.config.runtime.STATS_API_URL != ''
        ? state.config.runtime.STATS_API_URL
        : `${document.location.protocol}//${document.location.host}/stats/api/v1`;

export function getStatsApiAggregationsUrl(state: AppState) {
    return state.config.runtime.STATS_API_URL != undefined && state.config.runtime.STATS_API_URL != ''
        ? `${state.config.runtime.STATS_API_URL}/aggregations`
        : `${document.location.protocol}//${document.location.host}/stats/api/v1/aggregations`;
}
