import { BigNumber } from '@0x/utils';
import { Flex, IconButton, Text, Tooltip, useTheme } from '@chakra-ui/react';
import { getEtherscanLink, getFrontendLogger } from '@derivadex/utils';
import { createColumnHelper } from '@tanstack/react-table';
import ExplorerButton from 'components/Marketplace/OrderTables/ExplorerButton';
import NumberFormatMinimumValue from 'components/Marketplace/OrderTables/NumberFormatMinimumValue';
import Table from 'components/Table/Table';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import getIcons from 'theme/icons';
import { useAccount } from 'wagmi';

export default function StrategyUpdateTable({
    isDesktopView,
    data,
    paginationData,
}: {
    isDesktopView: boolean;
    data: any[];
    paginationData?: {
        isEnabled: boolean;
        currentPage: number;
        prevPage?: () => void;
        nextPage?: () => void;
        limitPerPage: number;
    };
}) {
    const { chain } = useAccount();
    const { t } = useTranslation();
    const { colors } = useTheme();

    const columnHelper = createColumnHelper<any>();
    const columns = [
        columnHelper.accessor('createdAt', {
            cell: (info) => format(new Date(info.getValue()), 'MM/dd/yy, pp'),
            header: t('date'),
        }),
        columnHelper.accessor('kind', {
            cell: (info: any) => (
                <Text>{`${
                    info.getValue() === 0
                        ? 'Deposit'
                        : info.getValue() === 1
                        ? 'Withdrawal Complete'
                        : info.getValue() === 2
                        ? 'Withdrawal Lock'
                        : 'Funding Payment'
                }`}</Text>
            ),
            header: t('type'),
        }),
        columnHelper.accessor('newAvailCollateral', {
            cell: (info) =>
                info.getValue() !== undefined ? (
                    <NumberFormatMinimumValue value={new BigNumber(info.getValue())} />
                ) : (
                    <Text>-</Text>
                ),
            header: t('available collateral'),
        }),
        columnHelper.accessor('newLockedCollateral', {
            cell: (info) =>
                info.getValue() !== null ? (
                    <NumberFormatMinimumValue value={new BigNumber(info.getValue())} />
                ) : (
                    <Text>-</Text>
                ),
            header: t('locked collateral'),
        }),
        columnHelper.accessor('amount', {
            cell: (info) => <NumberFormatMinimumValue value={new BigNumber(info.getValue())} />,
            header: t('amount'),
            meta: {
                isNumeric: true,
            },
        }),
        columnHelper.accessor((row) => row, {
            cell: (info) => (
                <>
                    <Flex>
                        <ExplorerButton
                            label={t('paymentsHistoryExplorerButtonDescription')}
                            url={`epoch/${info.getValue().epochId}/txOrdinal/${info.getValue().txOrdinal}`}
                        />
                        <a
                            href={getEtherscanLink(chain?.id ?? 100, info.getValue().txHash, 'transaction')}
                            target="_blank"
                            style={{ pointerEvents: 'inherit', display: info.getValue().txHash ? 'block' : 'none' }}
                        >
                            <Tooltip label={'View on Etherscan'} fontSize="1.5vh" placement={'left'}>
                                <IconButton
                                    size={isDesktopView ? 'xs' : 'sm'}
                                    verticalAlign="middle"
                                    aria-label={'View on Etherscan'}
                                    icon={getIcons('Etherscan', 6)}
                                    isRound
                                    onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                                        event.stopPropagation();
                                    }}
                                    isDisabled={info.getValue().txHash ? false : true}
                                />
                            </Tooltip>
                        </a>
                    </Flex>
                </>
            ),
            header: 'Actions',
        }),
    ];

    return (
        <div className="table-strategy-history">
            <Table
                isDesktopView={isDesktopView}
                columns={columns}
                data={data}
                emptyText={t('noData')}
                sortBy={[{ id: 'createdAt', desc: true }]}
                paginationData={paginationData}
            />
        </div>
    );
}
