import { Ordering, TopTradersOrdering, TopTradersResponseItem } from '@derivadex/types';
import { AppState } from 'store/slices';

export function getLeaderboardData(state: AppState): TopTradersResponseItem[] {
    if (state.leaderboard.leaderboardData.currentPage === 0) {
        return [];
    }
    return state.leaderboard.leaderboardData.cache[state.leaderboard.leaderboardData.currentPage - 1] ?? [];
}

export function getLoggedInTraderData(state: AppState): TopTradersResponseItem | null {
    return state.leaderboard.loggedInTraderData;
}

export function getLeaderboardDataCache(state: AppState) {
    return state.leaderboard.leaderboardData.cache;
}

export function getLeaderboardDataCurrentPage(state: AppState) {
    return state.leaderboard.leaderboardData.currentPage;
}

export function getLeaderboardDataCursor(state: AppState): number | null {
    return state.leaderboard.leaderboardData.nextCursor;
}

export function getLeaderboardTraderOrdering(state: AppState): TopTradersOrdering {
    return state.leaderboard.orderBy;
}

export function getLeaderboardOrdering(state: AppState): Ordering {
    return state.leaderboard.order;
}

export function getLeaderboardInitalLoadFlag(state: AppState): boolean {
    return state.leaderboard.initialLoadFlag;
}
