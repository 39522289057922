import { Box, Heading, Stat, StatArrow, StatGroup, StatHelpText, StatLabel, StatNumber } from '@chakra-ui/react';
import { useEffect } from 'react';
import { NumericFormat } from 'react-number-format';
import { useSelector } from 'react-redux';
import {
    getStatsBalanceData,
    getStatsDirtyFlag,
    getStatsFundingData,
    getStatsMakerVolumeData,
    getStatsRealizedPnlData,
    getStatsTakerVolumeData,
    getStatsTradeMiningData,
} from 'store/portfolio/selectors';
import { StatsResponseItem } from 'store/requestUtils';
import { getSelectedStrategy } from 'store/strategy/selectors';
import { getEthAddress } from 'store/web3/selectors';

export default function Overview() {
    const currentStrategy = useSelector(getSelectedStrategy);
    const traderAddress = useSelector(getEthAddress);

    const statsDirtyFlag = useSelector(getStatsDirtyFlag);

    const statsRealizedData: StatsResponseItem | undefined = useSelector(getStatsRealizedPnlData);
    const statsBalanceData: StatsResponseItem | undefined = useSelector(getStatsBalanceData);
    const statsFundingData: StatsResponseItem | undefined = useSelector(getStatsFundingData);
    const statsTradeMiningData: StatsResponseItem | undefined = useSelector(getStatsTradeMiningData);
    const statsMakerVolumeData: StatsResponseItem | undefined = useSelector(getStatsMakerVolumeData);
    const statsTakerVolumeData: StatsResponseItem | undefined = useSelector(getStatsTakerVolumeData);

    useEffect(() => {
        if (!currentStrategy || !traderAddress || !statsDirtyFlag) {
            return;
        }
    }, [currentStrategy, traderAddress, statsDirtyFlag]);

    return (
        <Box height="100%" overflow="scroll">
            <Heading fontSize={{ base: '2.2vh', lg: '2.7vh' }}>Portfolio Overview</Heading>
            <span>{currentStrategy?.strategy}</span>

            <StatGroup mt="1rem" mb="1rem">
                <Stat mr={'0.5rem'}>
                    <StatLabel fontSize={{ base: '1.5vh', lg: '1.8vh' }}>Available Collateral</StatLabel>
                    <StatNumber fontSize={{ base: '1.5vh', lg: '1.8vh' }}>
                        <NumericFormat
                            value={statsBalanceData?.maxValue.toNumber()}
                            displayType="text"
                            thousandSeparator={true}
                            fixedDecimalScale={true}
                            decimalScale={2}
                            suffix={' USD'}
                        />
                    </StatNumber>
                    {!statsBalanceData?.diffPercentage.isZero() ? (
                        <StatHelpText fontSize={{ base: '1.3vh', lg: '1.5vh' }}>
                            <StatArrow
                                type={
                                    statsBalanceData?.maxValue.gt(statsBalanceData?.minValue) ? 'increase' : 'decrease'
                                }
                            />
                            {statsBalanceData?.diffPercentage.decimalPlaces(2).toNumber()}%
                        </StatHelpText>
                    ) : (
                        <></>
                    )}
                </Stat>
                <Stat mr={'0.5rem'}>
                    <StatLabel fontSize={{ base: '1.5vh', lg: '1.8vh' }}>Realized Pnl</StatLabel>
                    <StatNumber fontSize={{ base: '1.5vh', lg: '1.8vh' }}>
                        <NumericFormat
                            value={statsRealizedData?.maxValue.toNumber()}
                            displayType="text"
                            thousandSeparator={true}
                            fixedDecimalScale={true}
                            decimalScale={2}
                            suffix={' USD'}
                        />
                    </StatNumber>
                    {!statsRealizedData?.diffPercentage.isZero() ? (
                        <StatHelpText fontSize={{ base: '1.3vh', lg: '1.5vh' }}>
                            <StatArrow
                                type={
                                    statsRealizedData?.maxValue.gt(statsRealizedData?.minValue)
                                        ? 'increase'
                                        : 'decrease'
                                }
                            />
                            {statsRealizedData?.diffPercentage.decimalPlaces(2).toNumber()}%
                        </StatHelpText>
                    ) : (
                        <></>
                    )}
                </Stat>
            </StatGroup>

            <StatGroup mt="1rem" mb="1rem">
                <Stat mr={'0.5rem'}>
                    <StatLabel fontSize={{ base: '1.5vh', lg: '1.8vh' }}>Trade Mining Rewards</StatLabel>
                    <StatNumber fontSize={{ base: '1.5vh', lg: '1.8vh' }}>
                        <NumericFormat
                            value={statsTradeMiningData?.maxValue.toNumber()}
                            displayType="text"
                            thousandSeparator={true}
                            fixedDecimalScale={true}
                            decimalScale={2}
                            suffix={' DDX'}
                        />
                    </StatNumber>
                    {!statsTradeMiningData?.diffPercentage.isZero() ? (
                        <StatHelpText fontSize={{ base: '1.3vh', lg: '1.5vh' }}>
                            <StatArrow
                                type={
                                    statsTradeMiningData?.maxValue.gt(statsTradeMiningData?.minValue)
                                        ? 'increase'
                                        : 'decrease'
                                }
                            />
                            {statsTradeMiningData?.diffPercentage.decimalPlaces(2).toNumber()}%
                        </StatHelpText>
                    ) : (
                        <></>
                    )}
                </Stat>
                <Stat mr={'0.5rem'}>
                    <StatLabel fontSize={{ base: '1.5vh', lg: '1.8vh' }}>Funding Payments</StatLabel>
                    <StatNumber fontSize={{ base: '1.5vh', lg: '1.8vh' }}>
                        <NumericFormat
                            value={statsFundingData?.maxValue.toNumber()}
                            displayType="text"
                            thousandSeparator={true}
                            fixedDecimalScale={true}
                            decimalScale={2}
                            suffix={' USD'}
                        />
                    </StatNumber>
                    {!statsFundingData?.diffPercentage.isZero() ? (
                        <StatHelpText fontSize={{ base: '1.3vh', lg: '1.5vh' }}>
                            <StatArrow
                                type={
                                    statsFundingData?.maxValue.gt(statsFundingData?.minValue) ? 'increase' : 'decrease'
                                }
                            />
                            {statsFundingData?.diffPercentage.decimalPlaces(2).toNumber()}%
                        </StatHelpText>
                    ) : (
                        <></>
                    )}
                </Stat>
            </StatGroup>
            <StatGroup mt="1rem" mb="1rem">
                <Stat mr={'0.5rem'}>
                    <StatLabel fontSize={{ base: '1.5vh', lg: '1.8vh' }}>Maker Volume</StatLabel>
                    <StatNumber fontSize={{ base: '1.5vh', lg: '1.8vh' }}>
                        <NumericFormat
                            value={statsMakerVolumeData?.maxValue.toNumber()}
                            displayType="text"
                            thousandSeparator={true}
                            fixedDecimalScale={true}
                            decimalScale={2}
                            suffix={' USD'}
                        />
                    </StatNumber>
                    {!statsMakerVolumeData?.diffPercentage.isZero() ? (
                        <StatHelpText fontSize={{ base: '1.3vh', lg: '1.5vh' }}>
                            <StatArrow
                                type={
                                    statsMakerVolumeData?.maxValue.gt(statsMakerVolumeData?.minValue)
                                        ? 'increase'
                                        : 'decrease'
                                }
                            />
                            {statsMakerVolumeData?.diffPercentage.decimalPlaces(2).toNumber()}%
                        </StatHelpText>
                    ) : (
                        <></>
                    )}
                </Stat>
                <Stat mr={'0.5rem'}>
                    <StatLabel fontSize={{ base: '1.5vh', lg: '1.8vh' }}>Taker Volume</StatLabel>
                    <StatNumber fontSize={{ base: '1.5vh', lg: '1.8vh' }}>
                        <NumericFormat
                            value={statsTakerVolumeData?.maxValue.toNumber()}
                            displayType="text"
                            thousandSeparator={true}
                            fixedDecimalScale={true}
                            decimalScale={2}
                            suffix={' USD'}
                        />
                    </StatNumber>
                    {!statsTakerVolumeData?.diffPercentage.isZero() ? (
                        <StatHelpText fontSize={{ base: '1.3vh', lg: '1.5vh' }}>
                            <StatArrow
                                type={
                                    statsTakerVolumeData?.maxValue.gt(statsTakerVolumeData?.minValue)
                                        ? 'increase'
                                        : 'decrease'
                                }
                            />
                            {statsTakerVolumeData?.diffPercentage.decimalPlaces(2).toNumber()}%
                        </StatHelpText>
                    ) : (
                        <></>
                    )}
                </Stat>
            </StatGroup>
        </Box>
    );
}
