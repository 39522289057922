import { BigNumber } from '@0x/utils';
import {
    AdlResponseItem,
    FeeResponseItem,
    Ordering,
    OrderIntentStatsResponseItem,
    PaginatedTopTradersResponse,
    PositionsResponseItem,
    StatsAPIListResponse,
    StatsAPITimeseriesResponse,
    StrategyUpdateResponseItem,
    TopTradersOrdering,
    TopTradersResponseItem,
    TradeResponseItem,
    TraderUpdateResponseItem,
    UIStrategy,
} from '@derivadex/types';

export enum PortfolioTableType {
    Positions,
    Trades,
    DepositsAndWithdrawals,
    TradeMiningRewards,
    FundingHistory,
    AdlHistory,
    FeeHistory,
}

export interface FetchLeaderboardDataParams {
    page: FetchDataState;
    orderBy: TopTradersOrdering;
    order: Ordering;
    traderFilter: string | undefined;
}

export enum FetchDataState {
    PrevPage,
    NextPage,
}

export type StatsResponseItem = {
    minValue: BigNumber;
    maxValue: BigNumber;
    diffPercentage: BigNumber;
};

export type BalanceResponseItem = {
    amount: number;
    timestamp: Date;
};

export type RealizedPnlResponseItem = {
    amount: number;
    timestamp: Date;
};

export type PortfolioPeriodFilter = '1h' | '1D' | '1W' | '1M';

export const makeRequest = async (url: string) => {
    const response = await fetch(url);
    return response;
};

export function buildUrl(
    url: string,
    baseUrlSlashParams: { [key: string]: string | number } | undefined,
    slashUrl: string,
    params: { [key: string]: undefined | string | number | string[] },
) {
    let fullUrl = url;
    if (baseUrlSlashParams !== undefined) {
        fullUrl =
            fullUrl +
            '/' +
            Object.entries(baseUrlSlashParams)
                .map(([key, value]) => `${key}/${value}`)
                .join('/');
    }
    fullUrl =
        fullUrl +
        `/${slashUrl}?` +
        Object.entries(params)
            .filter(([, value]) => value !== undefined)
            .map(([key, value]) =>
                Array.isArray(value) ? value.map((it) => `${key}=${it}`).join('&') : `${key}=${value}`,
            )
            .join('&');
    return fullUrl;
}

export function generateChartPeriodParams(filter: PortfolioPeriodFilter): {
    aggregationPeriod: string;
    lookbackCount: number;
} {
    let aggregationPeriod = 'minute';
    let lookbackCount = 60 * 12;
    switch (filter) {
        case '1h':
            aggregationPeriod = 'minute';
            lookbackCount = 60;
            break;
        case '1D':
            aggregationPeriod = 'hour';
            lookbackCount = 24;
            break;
        case '1W':
            aggregationPeriod = 'hour';
            lookbackCount = 24 * 7;
            break;
        case '1M':
            aggregationPeriod = 'day';
            lookbackCount = 30;
            break;
        default:
            break;
    }
    return { aggregationPeriod, lookbackCount };
}

export function generateStatsMetricsPeriodParams(filter: PortfolioPeriodFilter): {
    aggregationPeriod: string;
    lookbackCount: number;
} {
    let aggregationPeriod = 'minute';
    let lookbackCount = 60 * 12;
    switch (filter) {
        case '1h':
            aggregationPeriod = 'hour';
            lookbackCount = 2;
            break;
        case '1D':
            aggregationPeriod = 'day';
            lookbackCount = 2;
            break;
        case '1W':
            aggregationPeriod = 'week';
            lookbackCount = 2;
            break;
        case '1M':
            aggregationPeriod = 'month';
            lookbackCount = 2;
            break;
        default:
            break;
    }
    return { aggregationPeriod, lookbackCount };
}

export const parseStrategyUpdateResponse = async (response: Response) => {
    const json: any = await response.json();
    const parsedData: StatsAPITimeseriesResponse<StrategyUpdateResponseItem> = {
        success: json.success,
        timestamp: json.timestamp,
        nextEpoch: json.nextEpoch,
        nextOrdinal: json.nextOrdinal,
        nextTxOrdinal: json.nextTxOrdinal,
        value: json.value.map((item: any) => ({
            ...item,
            amount: new BigNumber(item.amount),
            createdAt: new Date(item.createdAt),
            newAvailCollateral: item.newAvailCollateral !== null ? new BigNumber(item.newAvailCollateral) : null,
            newLockedCollateral: item.newLockedCollateral !== null ? new BigNumber(item.newLockedCollateral) : null,
            newAvgEntryPrices: item.newAvgEntryPrices != null ? new BigNumber(item.newAvgEntryPrices) : null,
        })),
    };
    return parsedData;
};

export const parseDDXRewardsResponse = async (response: Response) => {
    const json: any = await response.json();
    const parsedData: StatsAPITimeseriesResponse<TraderUpdateResponseItem> = {
        success: json.success,
        timestamp: json.timestamp,
        nextEpoch: json.nextEpoch,
        nextOrdinal: json.nextOrdinal,
        nextTxOrdinal: json.nextTxOrdinal,
        value: json.value.map((item: any) => ({
            ...item,
            amount: new BigNumber(item.amount),
            createdAt: new Date(item.createdAt),
            newDDXBalance: new BigNumber(item.newDDXBalance),
        })),
    };
    return parsedData;
};

export const parsePositionResponse = async (response: Response) => {
    const json: any = await response.json();
    const parsedData: StatsAPIListResponse<PositionsResponseItem> = {
        success: json.success,
        timestamp: json.timestamp,
        value: json.value.map((item: any) => ({
            ...item,
            createdAt: new Date(item.createdAt),
            balance: new BigNumber(item.balance),
            avgEntryPrice: new BigNumber(item.avgEntryPrice),
        })),
    };
    return parsedData;
};

export const parseAdlResponse = async (response: Response) => {
    const json: any = await response.json();
    const parsedData: StatsAPITimeseriesResponse<AdlResponseItem> = {
        success: json.success,
        timestamp: json.timestamp,
        nextEpoch: json.nextEpoch,
        nextOrdinal: json.nextOrdinal,
        nextTxOrdinal: json.nextTxOrdinal,
        value: json.value.map((item: any) => ({
            ...item,
            amount: new BigNumber(item.amount),
            createdAt: new Date(item.createdAt),
        })),
    };
    return parsedData;
};

export const parseTradesResponse = async (response: Response) => {
    const json: any = await response.json();
    const parsedData: StatsAPITimeseriesResponse<TradeResponseItem> = {
        success: json.success,
        timestamp: json.timestamp,
        nextEpoch: json.nextEpoch,
        nextOrdinal: json.nextOrdinal,
        nextTxOrdinal: json.nextTxOrdinal,
        value: json.value.map((item: any) => ({
            ...item,
            amount: new BigNumber(item.amount),
            price: new BigNumber(item.price),
            fee: new BigNumber(item.fee),
            realizedPnl: new BigNumber(item.realizedPnl),
            createdAt: new Date(item.createdAt),
        })),
    };
    return parsedData;
};

export const parseStatsResponse = async (response: Response) => {
    const json: any = await response.json();
    if (json.value) {
        if (json.value.length >= 2) {
            const minValue = new BigNumber(json.value[0].amount);
            const maxValue = new BigNumber(json.value[json.value.length - 1].amount);
            const result = {
                minValue,
                maxValue,
                diffPercentage: percentage(minValue, maxValue),
            };
            return result;
        } else if (json.value.length === 1) {
            const maxValue = new BigNumber(json.value[json.value.length - 1].amount);
            const result = {
                minValue: new BigNumber(0),
                maxValue,
                diffPercentage: new BigNumber(0),
            };
            return result;
        }
    }
    return {
        minValue: new BigNumber(0),
        maxValue: new BigNumber(0),
        diffPercentage: new BigNumber(0),
    } as StatsResponseItem;
};

export const parseStatsBalanceResponse = async (response: Response, strategy: UIStrategy) => {
    const json: any = await response.json();
    if (json.value) {
        if (json.value.length >= 2) {
            const minValue = new BigNumber(json.value[0].amount);
            const maxValue = strategy.frontendMargin;
            const result = {
                minValue,
                maxValue,
                diffPercentage: percentage(minValue, maxValue),
            };
            return result;
        } else if (json.value.length === 1) {
            const maxValue = strategy.frontendMargin;
            const result = {
                minValue: new BigNumber(0),
                maxValue,
                diffPercentage: new BigNumber(0),
            };
            return result;
        }
    }
    return {
        minValue: new BigNumber(0),
        maxValue: new BigNumber(0),
        diffPercentage: new BigNumber(0),
    } as StatsResponseItem;
};

export const parseStatsVolumeResponse = async (
    response: Response,
): Promise<{ maker: StatsResponseItem; taker: StatsResponseItem }> => {
    const json: any = await response.json();
    if (json.value) {
        if (json.value.length >= 2) {
            const minMakerVolume = new BigNumber(json.value[0].makerVolume);
            const minTakerVolume = new BigNumber(json.value[0].takerVolume);

            const maxMakerVolume = new BigNumber(json.value[json.value.length - 1].makerVolume);
            const maxTakerVolume = new BigNumber(json.value[json.value.length - 1].takerVolume);
            const result = {
                maker: {
                    minValue: minMakerVolume,
                    maxValue: maxMakerVolume,
                    diffPercentage: percentage(minMakerVolume, maxMakerVolume),
                },
                taker: {
                    minValue: minTakerVolume,
                    maxValue: maxTakerVolume,
                    diffPercentage: percentage(minTakerVolume, maxTakerVolume),
                },
            };
            return result;
        } else if (json.value.length === 1) {
            const maxMakerVolume = new BigNumber(json.value[json.value.length - 1].makerVolume);
            const maxTakerVolume = new BigNumber(json.value[json.value.length - 1].takerVolume);
            const maxValue = maxTakerVolume.plus(maxMakerVolume);
            const result = {
                maker: {
                    minValue: new BigNumber(0),
                    maxValue,
                    diffPercentage: new BigNumber(0),
                },
                taker: {
                    minValue: new BigNumber(0),
                    maxValue,
                    diffPercentage: new BigNumber(0),
                },
            };
            return result;
        }
    }
    return {
        maker: {
            minValue: new BigNumber(0),
            maxValue: new BigNumber(0),
            diffPercentage: new BigNumber(0),
        },
        taker: {
            minValue: new BigNumber(0),
            maxValue: new BigNumber(0),
            diffPercentage: new BigNumber(0),
        },
    };
};

export const parseBalanceResponse = async (response: Response) => {
    const json: any = await response.json();
    const parsedData: BalanceResponseItem[] = json.value.map((item: any) => ({
        amount: parseInt(item.amount),
        timestamp: new Date(item.timestamp * 1000).toLocaleDateString(undefined, {
            timeZone: 'UTC',
            minute: 'numeric',
            hour: 'numeric',
            day: 'numeric',
            month: 'numeric',
        }),
    }));
    return parsedData;
};

export const parseTopTradersResponse = async (response: Response): Promise<PaginatedTopTradersResponse> => {
    const json: any = await response.json();
    const traders = json.value;
    const parsedTradersData: TopTradersResponseItem[] = traders.map((item: any) => ({
        trader: item.trader,
        volume: new BigNumber(item.volume),
        realizedPnl: new BigNumber(item.realizedPnl),
        accountValue: new BigNumber(item.accountValue),
    }));
    return {
        traders: parsedTradersData,
        nextCursor: json.nextCursor,
    };
};

export const parseFeeResponse = async (response: Response) => {
    const json: any = await response.json();
    const parsedData: StatsAPITimeseriesResponse<FeeResponseItem> = {
        success: json.success,
        timestamp: json.timestamp,
        nextEpoch: json.nextEpoch,
        nextOrdinal: json.nextOrdinal,
        nextTxOrdinal: json.nextTxOrdinal,
        value: json.value.map((item: any) => ({
            ...item,
            amount: new BigNumber(item.amount),
            createdAt: new Date(item.createdAt),
        })),
    };
    return parsedData;
};

export const parseRealizedPnlResponse = async (response: Response) => {
    const json: any = await response.json();
    const parsedData: RealizedPnlResponseItem[] = json.value.map((item: any) => ({
        amount: parseInt(item.amount),
        timestamp: new Date(item.timestamp * 1000).toLocaleDateString(undefined, {
            timeZone: 'UTC',
            minute: 'numeric',
            hour: 'numeric',
            day: 'numeric',
            month: 'numeric',
        }),
    }));
    return parsedData;
};

export const parseOrderIntentResponse = async (response: Response) => {
    const json: any = await response.json();
    const parsedData: StatsAPITimeseriesResponse<OrderIntentStatsResponseItem> = {
        success: json.success,
        timestamp: json.timestamp,
        nextEpoch: json.nextEpoch,
        nextOrdinal: json.nextOrdinal,
        nextTxOrdinal: json.nextTxOrdinal,
        value: json.value.map((item: any) => ({
            ...item,
            amount: new BigNumber(item.amount),
            remainingAmount: new BigNumber(item.remainingAmount),
            price: new BigNumber(item.price),
            stopPrice: new BigNumber(item.stopPrice),
            createdAt: new Date(item.createdAt),
        })),
    };
    return parsedData;
};

const percentage = (minValue: BigNumber, maxValue: BigNumber): BigNumber => {
    if (maxValue.eq(minValue)) {
        return new BigNumber(0);
    }
    if (maxValue.isZero()) {
        return new BigNumber(100);
    }
    return maxValue.multipliedBy(100).dividedBy(minValue).minus(100).abs();
};
