import { BigNumber } from '@0x/utils';
import { Flex, Text, useTheme } from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import ExplorerButton from 'components/Marketplace/OrderTables/ExplorerButton';
import NumberFormatMinimumValue from 'components/Marketplace/OrderTables/NumberFormatMinimumValue';
import Table from 'components/Table/Table';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';

export default function TraderUpdateTable({
    isDesktopView,
    data,
    paginationData,
}: {
    isDesktopView: boolean;
    data: any[];
    paginationData?: {
        isEnabled: boolean;
        currentPage: number;
        prevPage?: () => void;
        nextPage?: () => void;
        limitPerPage?: number;
    };
}) {
    const { t } = useTranslation();
    const { colors } = useTheme();

    const columnHelper = createColumnHelper<any>();
    const columns = [
        columnHelper.accessor('createdAt', {
            cell: (info) => format(new Date(info.getValue()), 'MM/dd/yy, pp'),
            header: t('date'),
        }),
        columnHelper.accessor('kind', {
            cell: (info: any) => (
                <Text>{`${info.getValue() === 0 ? 'Deposit' : info.getValue() === 1 ? 'Withdrawal' : 'Reward'}`}</Text>
            ),
            header: t('type'),
        }),
        columnHelper.accessor('amount', {
            cell: (info) => <NumberFormatMinimumValue value={new BigNumber(info.getValue())} />,
            header: t('amount'),
            meta: {
                isNumeric: true,
            },
        }),
        columnHelper.accessor((row) => row, {
            cell: (info) => (
                <>
                    <Flex>
                        <ExplorerButton
                            label={t('paymentsHistoryExplorerButtonDescription')}
                            url={`epoch/${info.getValue().epochId}/txOrdinal/${info.getValue().txOrdinal}`}
                        />
                    </Flex>
                </>
            ),
            header: 'Actions',
        }),
    ];

    return (
        <div className="table-trader-history">
            <Table
                isDesktopView={isDesktopView}
                columns={columns}
                data={data}
                emptyText={t('noData')}
                sortBy={[{ id: 'createdAt', desc: true }]}
                paginationData={paginationData}
            />
        </div>
    );
}
