import { ActionType, UIStrategy } from '@derivadex/types';
import { getErrorMessage, getFrontendLogger } from '@derivadex/utils';
import { getStatsApiAggregationsUrl, getStatsApiUrl } from 'store/config/selectors';
import { waitFor } from 'store/saga';
import { getSelectedStrategy } from 'store/strategy/selectors';
import { getEthAddress } from 'store/web3/selectors';
import { all, call, fork, putResolve, select, takeLatest } from 'typed-redux-saga/macro';

import {
    buildUrl,
    generateChartPeriodParams,
    generateStatsMetricsPeriodParams,
    makeRequest,
    parseBalanceResponse,
    parseRealizedPnlResponse,
    parseStatsBalanceResponse,
    parseStatsResponse,
    parseStatsVolumeResponse,
} from '../requestUtils';
import { handlePositionHistoryDirtyFlag } from './sagaTables';
import { getPortfolioFilter } from './selectors';
import {
    RESET_TABLES,
    SET_BALANCE_DATA,
    SET_LOOKBACK_FILTER,
    SET_REALIZED_PNL_DATA,
    SET_STATS_BALANCE_DATA,
    SET_STATS_DIRTY_FLAG,
    SET_STATS_FUNDING_DATA,
    SET_STATS_REALIZED_DATA,
    SET_STATS_TRADE_MINING_DATA,
    SET_STATS_VOLUME_DATA,
    UPDATE_LOOKBACK_PERIOD_FILTER,
} from './slice';

function* handleFetchStatsRealized(): Generator {
    try {
        const traderAddress = yield* select(getEthAddress);
        const strategy = yield* select(getSelectedStrategy);
        const filter = yield* select(getPortfolioFilter);
        const statsApiAggrUrl = yield* select(getStatsApiAggregationsUrl);
        if (traderAddress === undefined || strategy === undefined) {
            getFrontendLogger().logError('Trader address or strategy cannot be undefined');
            return;
        }
        const { aggregationPeriod, lookbackCount } = generateStatsMetricsPeriodParams(filter);
        const url = buildUrl(
            `${statsApiAggrUrl}`,
            { account: traderAddress, strategy: strategy.strategy },
            'realized_pnl',
            { aggregationPeriod, lookbackCount },
        );
        const response = yield* call(makeRequest, url);
        const parsedResponse = yield* call(parseStatsResponse, response, traderAddress!);
        yield* putResolve(SET_STATS_REALIZED_DATA(parsedResponse));
    } catch (error: any) {
        getFrontendLogger().logError('caught exception in fetching stats data', getErrorMessage(error));
    }
}

function* handleFetchStatsBalance(): Generator {
    try {
        const traderAddress = yield* select(getEthAddress);
        const strategy = yield* select(getSelectedStrategy);
        const filter = yield* select(getPortfolioFilter);
        const statsApiAggrUrl = yield* select(getStatsApiAggregationsUrl);
        if (traderAddress === undefined || strategy === undefined) {
            getFrontendLogger().logError('Trader address or strategy cannot be undefined');
            return;
        }
        const { aggregationPeriod, lookbackCount } = generateStatsMetricsPeriodParams(filter);
        const url = buildUrl(`${statsApiAggrUrl}`, { account: traderAddress, strategy: strategy.strategy }, 'balance', {
            aggregationPeriod,
            lookbackCount,
        });
        const response = yield* call(makeRequest, url);
        const parsedResponse = yield* call(parseStatsBalanceResponse, response, strategy);
        yield* putResolve(SET_STATS_BALANCE_DATA(parsedResponse));
    } catch (error: any) {
        getFrontendLogger().logError('caught exception in fetching stats balance data', getErrorMessage(error));
    }
}

function* handleFetchStatsFunding(): Generator {
    try {
        const traderAddress = yield* select(getEthAddress);
        const strategy = yield* select(getSelectedStrategy);
        const filter = yield* select(getPortfolioFilter);
        const statsApiAggrUrl = yield* select(getStatsApiAggregationsUrl);
        if (traderAddress === undefined || strategy === undefined) {
            getFrontendLogger().logError('Trader address or strategy cannot be undefined');
            return;
        }
        const { aggregationPeriod, lookbackCount } = generateStatsMetricsPeriodParams(filter);
        const url = buildUrl(
            `${statsApiAggrUrl}`,
            { account: traderAddress, strategy: strategy.strategy },
            'funding_rate_payments',
            { aggregationPeriod, lookbackCount },
        );
        const response = yield* call(makeRequest, url);
        const parsedResponse = yield* call(parseStatsResponse, response, traderAddress!);
        yield* putResolve(SET_STATS_FUNDING_DATA(parsedResponse));
    } catch (error: any) {
        getFrontendLogger().logError('caught exception in fetching stats funding data', getErrorMessage(error));
    }
}

function* handleFetchStatsTradeMining(): Generator {
    try {
        const traderAddress = yield* select(getEthAddress);
        const filter = yield* select(getPortfolioFilter);
        const statsApiAggrUrl = yield* select(getStatsApiAggregationsUrl);
        if (traderAddress === undefined) {
            getFrontendLogger().logError('Trader address or strategy cannot be undefined');
            return;
        }
        const { aggregationPeriod, lookbackCount } = generateStatsMetricsPeriodParams(filter);
        const url = buildUrl(`${statsApiAggrUrl}`, { account: traderAddress }, 'trade_mining_rewards', {
            aggregationPeriod,
            lookbackCount,
        });
        const response = yield* call(makeRequest, url);
        const parsedResponse = yield* call(parseStatsResponse, response, traderAddress!);
        yield* putResolve(SET_STATS_TRADE_MINING_DATA(parsedResponse));
    } catch (error: any) {
        getFrontendLogger().logError('caught exception in fetching stats mining data', getErrorMessage(error));
    }
}

function* handleFetchStatsVolume(): Generator {
    try {
        const traderAddress = yield* select(getEthAddress);
        const strategy = yield* select(getSelectedStrategy);
        const filter = yield* select(getPortfolioFilter);
        const statsApiAggrUrl = yield* select(getStatsApiAggregationsUrl);
        if (traderAddress === undefined || strategy === undefined) {
            getFrontendLogger().logError('Trader address or strategy cannot be undefined');
            return;
        }
        const { aggregationPeriod, lookbackCount } = generateStatsMetricsPeriodParams(filter);
        const url = buildUrl(`${statsApiAggrUrl}`, { account: traderAddress, strategy: strategy.strategy }, 'volume', {
            aggregationPeriod,
            lookbackCount,
        });
        const response = yield* call(makeRequest, url);
        const parsedResponse = yield* call(parseStatsVolumeResponse, response, traderAddress!);
        yield* putResolve(SET_STATS_VOLUME_DATA(parsedResponse));
    } catch (error: any) {
        getFrontendLogger().logError('caught exception in fetching stats volume data', getErrorMessage(error));
    }
}

function* handleFetchStats(): Generator {
    yield* all([
        fork(handleFetchStatsVolume),
        fork(handleFetchStatsTradeMining),
        fork(handleFetchStatsFunding),
        fork(handleFetchStatsBalance),
        fork(handleFetchStatsRealized),
    ]);
    yield* putResolve(SET_STATS_DIRTY_FLAG(false));
}

export function* watchStatsDirtyFlag() {
    yield* takeLatest(ActionType.FETCH_STATS_DATA, handleFetchStats);
}

function* handleBalanceDirtyFlag(): Generator {
    try {
        const traderAddress = yield* select(getEthAddress);
        const strategy = yield* select(getSelectedStrategy);
        const filter = yield* select(getPortfolioFilter);
        const statsApiAggrUrl = yield* select(getStatsApiAggregationsUrl);
        if (traderAddress === undefined || strategy === undefined) {
            getFrontendLogger().logError('Trader address or strategy cannot be undefined');
            return;
        }
        const { aggregationPeriod, lookbackCount } = generateChartPeriodParams(filter);
        const url = buildUrl(`${statsApiAggrUrl}`, { account: traderAddress, strategy: strategy.strategy }, 'balance', {
            aggregationPeriod,
            lookbackCount,
        });
        const response = yield* call(makeRequest, url);
        const parsedResponse = yield* call(parseBalanceResponse, response!);
        yield* putResolve(SET_BALANCE_DATA(parsedResponse));
    } catch (error: any) {
        getFrontendLogger().logError('caught exception in fetching balance data', getErrorMessage(error));
    }
}

export function* watchBalanceDirtyFlag() {
    yield* takeLatest(ActionType.FETCH_BALANCE_DATA, handleBalanceDirtyFlag);
}

function* handleRealizedPnlDirtyFlag(): Generator {
    try {
        const traderAddress = yield* select(getEthAddress);
        const strategy = yield* select(getSelectedStrategy);
        const filter = yield* select(getPortfolioFilter);
        const statsApiAggrUrl = yield* select(getStatsApiAggregationsUrl);
        if (traderAddress === undefined || strategy === undefined) {
            getFrontendLogger().logError('Trader address or strategy cannot be undefined');
            return;
        }
        const { aggregationPeriod, lookbackCount } = generateChartPeriodParams(filter);
        const url = buildUrl(
            `${statsApiAggrUrl}`,
            { account: traderAddress, strategy: strategy.strategy },
            'realized_pnl',
            { aggregationPeriod, lookbackCount },
        );
        const response = yield* call(makeRequest, url);
        const parsedResponse = yield* call(parseRealizedPnlResponse, response!);
        yield* putResolve(SET_REALIZED_PNL_DATA(parsedResponse));
    } catch (error: any) {
        getFrontendLogger().logError('caught exception in fetching realized data', getErrorMessage(error));
    }
}

export function* watchRealizedPnlDirtyFlag() {
    yield* takeLatest(ActionType.FETCH_REALIZED_PNL_DATA, handleRealizedPnlDirtyFlag);
}

function* handleLookbackPeriodFilter(action: ReturnType<typeof UPDATE_LOOKBACK_PERIOD_FILTER>): Generator {
    try {
        const filter = action.payload;
        yield* call(waitFor<UIStrategy>, getSelectedStrategy);
        yield* putResolve(SET_LOOKBACK_FILTER(filter));
        yield* all([fork(handleRealizedPnlDirtyFlag), fork(handleFetchStats)]);
    } catch (error: any) {
        getFrontendLogger().logError('caught exception in handling lookback period filter', getErrorMessage(error));
    }
}

function* handleReset(): Generator {
    try {
        yield* call(waitFor<UIStrategy>, getSelectedStrategy);
        yield* putResolve(RESET_TABLES());
        yield* all([fork(handleRealizedPnlDirtyFlag), fork(handleFetchStats), fork(handlePositionHistoryDirtyFlag)]);
    } catch (error: any) {
        getFrontendLogger().logError('caught exception in handling lookback period filter', getErrorMessage(error));
    }
}

export function* watchLookbackPeriodFilter() {
    yield* takeLatest(ActionType.UPDATE_LOOKBACK_PERIOD_FILTER, handleLookbackPeriodFilter);
}

export function* watchResetPortfolioPage() {
    yield* takeLatest(ActionType.RESET_PORTFOLIO_PAGE, handleReset);
}

/**
 * The portfolio saga layer is responsable for handling side effects for the portfolio dashboard
 */
export const portfolioStatsSaga = function* root() {
    yield* all([
        fork(watchBalanceDirtyFlag),
        fork(watchRealizedPnlDirtyFlag),
        fork(watchStatsDirtyFlag),
        fork(watchLookbackPeriodFilter),
        fork(watchResetPortfolioPage),
    ]);
};
