import { Box, CircularProgress, Divider, Grid } from '@chakra-ui/react';
import { ChainConnectionStatus } from '@derivadex/types';
import { ErrorBoundary } from '@sentry/react';
import MarketplaceFooter from 'pages/MarketplaceFooter';
import { lazy, ReactNode, Suspense } from 'react';
import { useSelector } from 'react-redux';
import {
    isDepositCollateralsDialogActive,
    isMarketsDialogActive,
    isStrategiesDialogActive,
    isWalletDialogActive,
    isWithdrawDdxDialogActive,
    isWithdrawTokensDialogActive,
} from 'store/ui/selectors';
import { getNetworkStatus } from 'store/web3/selectors';
import { useAccount } from 'wagmi';

import CardGridItem from './CardGridItem';
import MobileBuySellPanel from './Marketplace/BuySell/MobileBuySellPanel';
import EnableOneClickTradingDialog from './Marketplace/BuySell/Tabs/EnableOneClickTradingDialog';
import SoundEffects from './SoundEffects';
import Stats from './Stats/Stats';
import Strategies from './Strategies/Strategies';

const Header = lazy(() => import('./Header'));
const Wallets = lazy(() => import('./Wallet/Wallets'));
const SwitchNetwork = lazy(() => import('./Wallet/SwitchNetwork'));
const Markets = lazy(() => import('./Markets'));
const Deposits = lazy(() => import('./Deposits'));
const Validations = lazy(() => import('./Toasts/Validations'));
const WithdrawTokensContainer = lazy(() => import('./WithdrawTokens/WithdrawTokensContainer'));
const WithdrawDDXContainer = lazy(() => import('./WithdrawDDX/WithdrawDDXContainer'));

export default function Layout({ isDesktopView, children }: { isDesktopView: boolean; children: ReactNode }) {
    const shouldLoadWallets = useSelector(isWalletDialogActive);
    const shouldLoadMarkets = useSelector(isMarketsDialogActive);
    const shouldLoadDeposits = useSelector(isDepositCollateralsDialogActive);
    const shouldLoadWithdrawTokensContainer = useSelector(isWithdrawTokensDialogActive);
    const shouldLoadWithdrawDDXContainer = useSelector(isWithdrawDdxDialogActive);
    const shouldLoadStrategies = useSelector(isStrategiesDialogActive);
    const networkStatus = useSelector(getNetworkStatus);
    const { isConnected } = useAccount();
    return isDesktopView ? (
        <>
            <Header />
            <SoundEffects />
            <Suspense fallback={<CircularProgress isIndeterminate />}>{isConnected && <SoundEffects />}</Suspense>
            <Box as="main" h="calc(100vh - 5rem)">
                {children}
            </Box>
            <Box borderRadius="base" bgColor="card.100" p="0rem">
                <MarketplaceFooter />
            </Box>
            <Suspense fallback={<CircularProgress isIndeterminate />}>{shouldLoadWallets && <Wallets />}</Suspense>
            <Suspense fallback={<CircularProgress isIndeterminate />}>{shouldLoadMarkets && <Markets />}</Suspense>
            <Suspense fallback={<CircularProgress isIndeterminate />}>
                {shouldLoadStrategies && <Strategies />}
            </Suspense>
            <Suspense fallback={<CircularProgress isIndeterminate />}>
                {networkStatus === ChainConnectionStatus.INCORRECT && <SwitchNetwork />}
            </Suspense>
            <Suspense fallback={<CircularProgress isIndeterminate />}>{shouldLoadDeposits && <Deposits />}</Suspense>
            <Suspense fallback={<CircularProgress isIndeterminate />}>
                {shouldLoadWithdrawTokensContainer && <WithdrawTokensContainer />}
            </Suspense>
            <Suspense fallback={<CircularProgress isIndeterminate />}>
                {shouldLoadWithdrawDDXContainer && <WithdrawDDXContainer />}
            </Suspense>
            <Suspense fallback={<CircularProgress isIndeterminate />}>{isConnected && <Validations />}</Suspense>
            <Suspense fallback={<CircularProgress isIndeterminate />}>
                {isConnected && <EnableOneClickTradingDialog />}
            </Suspense>
        </>
    ) : (
        <>
            <SoundEffects />
            <Suspense fallback={<CircularProgress isIndeterminate />}>{isConnected && <SoundEffects />}</Suspense>
            <Grid
                gridGap={1}
                gridTemplateColumns="100vw"
                gridTemplateRows="7vh auto 85vh"
                gridTemplateAreas="
                    'Header'
                    'Stats'
                    'Children'"
                minH="100vh"
                maxH="100vh"
                minW="100vw"
                maxW="100vw"
            >
                <CardGridItem gridArea="Header">
                    <ErrorBoundary>
                        <Header />
                    </ErrorBoundary>
                </CardGridItem>
                <CardGridItem gridArea="Stats" p="0.5rem">
                    <ErrorBoundary>
                        <Stats />
                    </ErrorBoundary>
                </CardGridItem>
                <CardGridItem gridArea="Children" p="0.5rem">
                    {children}
                </CardGridItem>
            </Grid>
            <Box position="fixed" bottom="0" left="0" right="0" my="1vh" mx="5vw" zIndex="sticky">
                <MobileBuySellPanel />
                <MarketplaceFooter />
            </Box>
            <Suspense fallback={<CircularProgress isIndeterminate />}>{shouldLoadWallets && <Wallets />}</Suspense>
            <Suspense fallback={<CircularProgress isIndeterminate />}>{shouldLoadMarkets && <Markets />}</Suspense>
            <Suspense fallback={<CircularProgress isIndeterminate />}>
                {shouldLoadStrategies && <Strategies />}
            </Suspense>
            <Suspense fallback={<CircularProgress isIndeterminate />}>
                {networkStatus === ChainConnectionStatus.INCORRECT && <SwitchNetwork />}
            </Suspense>
            <Suspense fallback={<CircularProgress isIndeterminate />}>{shouldLoadDeposits && <Deposits />}</Suspense>
            <Suspense fallback={<CircularProgress isIndeterminate />}>
                {shouldLoadWithdrawTokensContainer && <WithdrawTokensContainer />}
            </Suspense>
            <Suspense fallback={<CircularProgress isIndeterminate />}>
                {shouldLoadWithdrawDDXContainer && <WithdrawDDXContainer />}
            </Suspense>
            <Suspense fallback={<CircularProgress isIndeterminate />}>{isConnected && <Validations />}</Suspense>
            <Suspense fallback={<CircularProgress isIndeterminate />}>
                {isConnected && <EnableOneClickTradingDialog />}
            </Suspense>
        </>
    );
}
