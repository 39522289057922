import { Box, Flex, Grid, Heading, TabList, TabPanels, Tabs, Text } from '@chakra-ui/react';
import ChartRealizedPnl from 'components/AccountPortfolio/Charts/ChartRealizedPnl';
import ChartsTabs from 'components/AccountPortfolio/Charts/ChartTabs';
import PortfolioHeader from 'components/AccountPortfolio/Header/PortfolioHeader';
import Overview from 'components/AccountPortfolio/Overview';
import PortfolioTables from 'components/AccountPortfolio/Tables/PortfolioTables';
import CardGridItem from 'components/CardGridItem';
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';
import MobileTab from 'components/MobileTab';
import MobileTabPanel from 'components/MobileTabPanel';
import useLocalStorage from 'hooks/useLocalStorage';
import { useScreenSize } from 'hooks/useScreenSize';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FiList } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { SET_SELECTED_MARKET } from 'store/market/slice';
import { UIViewState, UPDATE_CURRENT_VIEW } from 'store/ui/slice';
import { isWeb3Connected } from 'store/web3/selectors';
import { DDXIcon } from 'theme/icons';

import Layout from '../components/Layout';
import MarketplaceFooter from './MarketplaceFooter';

export default function AccountPortfolio() {
    const isDesktopView = useScreenSize();
    const { t } = useTranslation();
    const [activeTabIndex, setActiveTabIndex] = useState<number>(0);
    const dispatch = useDispatch();
    const isAppConnected = useSelector(isWeb3Connected);

    const [cachedMarket, _setCachedMarket] = useLocalStorage<string>('selectedMarket', 'ETHP');

    useEffect(() => {
        dispatch(SET_SELECTED_MARKET({ symbol: cachedMarket }));
    }, []);

    return (
        <Layout isDesktopView={isDesktopView}>
            {isAppConnected ? (
                isDesktopView ? (
                    <>
                        <Grid
                            w="100%"
                            gridGap={1}
                            p="0.25rem"
                            gridTemplateColumns="140px 1fr 1fr 140px"
                            gridTemplateRows="50px 3fr 3fr 3fr 3fr"
                            gridTemplateAreas="
                                'EmptyLeft PortfolioHeader PortfolioHeader EmptyRight'
                                'EmptyLeft Overview Charts EmptyRight'
                                'EmptyLeft Overview Charts EmptyRight'
                                'EmptyLeft Tables Tables EmptyRight'
                                'EmptyLeft Tables Tables EmptyRight'"
                            h="100%"
                        >
                            <CardGridItem gridArea="PortfolioHeader" p="0.5rem">
                                <ErrorBoundary>
                                    <PortfolioHeader />
                                </ErrorBoundary>
                            </CardGridItem>
                            <CardGridItem gridArea="Overview" p="1rem">
                                <ErrorBoundary>
                                    <Overview />
                                </ErrorBoundary>
                            </CardGridItem>
                            <CardGridItem gridArea="Charts">
                                <ErrorBoundary>
                                    <ChartsTabs />
                                </ErrorBoundary>
                            </CardGridItem>
                            <CardGridItem gridArea="Tables">
                                <PortfolioTables />
                            </CardGridItem>
                        </Grid>
                        <Box borderRadius="base" bgColor="card.100" p="0rem">
                            <MarketplaceFooter />
                        </Box>
                    </>
                ) : (
                    <Tabs isFitted defaultIndex={activeTabIndex} colorScheme="white">
                        <TabList
                            bottom={0}
                            position="relative"
                            w="100%"
                            bgColor="card.100"
                            borderTop="1px solid rgba(255,255,255,.06)"
                            color="text.100"
                        >
                            <MobileTab
                                icon={DDXIcon}
                                text={'Overview'}
                                index={0}
                                onClick={() => {
                                    setActiveTabIndex(0);
                                }}
                            />
                            <MobileTab
                                icon={FiList}
                                text={'History'}
                                index={1}
                                onClick={() => {
                                    setActiveTabIndex(1);
                                }}
                            />
                        </TabList>
                        <TabPanels>
                            <MobileTabPanel p="1rem">
                                <Flex mt="0.5rem" mb="0.5rem" direction="column" gap="0.5rem" h="100%" w="100%">
                                    <PortfolioHeader />
                                    <Overview />
                                    <Heading fontSize={{ base: '2.2vh', lg: '2.7vh' }}>Realized Pnl</Heading>
                                    <ChartRealizedPnl />
                                </Flex>
                            </MobileTabPanel>
                            <MobileTabPanel>
                                <PortfolioTables />
                            </MobileTabPanel>
                        </TabPanels>
                    </Tabs>
                )
            ) : (
                <Box mt={'1rem'}>
                    <Text align={'center'}>You need to connect to access this page.</Text>
                </Box>
            )}
        </Layout>
    );
}
