import { ActionType } from '@derivadex/types';
import { getErrorMessage, getFrontendLogger } from '@derivadex/utils';
import { getStatsApiUrl } from 'store/config/selectors';
import { getSelectedStrategy } from 'store/strategy/selectors';
import { getEthAddress } from 'store/web3/selectors';
import { all, call, fork, putResolve, select, takeLatest } from 'typed-redux-saga/macro';

import {
    buildUrl,
    FetchDataState,
    makeRequest,
    parseAdlResponse,
    parseDDXRewardsResponse,
    parseFeeResponse,
    parsePositionResponse,
    parseStrategyUpdateResponse,
    parseTradesResponse,
} from '../requestUtils';
import {
    getAdlHistoryCache,
    getAdlHistoryCurrentPage,
    getAdlHistoryFilters,
    getAdlHistoryNextPageParams,
    getDDXRewardsHistoryCache,
    getDDXRewardsHistoryCurrentPage,
    getDDXRewardsHistoryNextPageParams,
    getDepositsWithdrawalsHistoryCache,
    getDepositsWithdrawalsHistoryCurrentPage,
    getDepositsWithdrawalsHistoryNextPageParams,
    getFeesHistoryCache,
    getFeesHistoryCurrentPage,
    getFeesHistoryFilters,
    getFeesHistoryNextPageParams,
    getFundingHistoryCache,
    getFundingHistoryCurrentPage,
    getFundingHistoryNextPageParams,
    getPositionsHistoryDirtyFlag,
    getTradesHistoryCache,
    getTradesHistoryCurrentPage,
    getTradesHistoryFilters,
    getTradesHistoryNextPageParams,
} from './selectors';
import {
    FETCH_ADL_HISTORY_DATA,
    FETCH_DDX_REWARDS_HISTORY_DATA,
    FETCH_DEPOSITS_AND_WITHDRAWALS_HISTORY_DATA,
    FETCH_FEES_HISTORY_DATA,
    FETCH_FUNDING_HISTORY_DATA,
    FETCH_TRADES_HISTORY_DATA,
    SET_ADL_HISTORY_CURRENT_PAGE,
    SET_ADL_HISTORY_DATA,
    SET_DDX_REWARDS_HISTORY_CURRENT_PAGE,
    SET_DDX_REWARDS_HISTORY_DATA,
    SET_DEPOSITS_WITHDRAWALS_HISTORY_CURRENT_PAGE,
    SET_DEPOSITS_WITHDRAWALS_HISTORY_DATA,
    SET_FEES_HISTORY_CURRENT_PAGE,
    SET_FEES_HISTORY_DATA,
    SET_FUNDING_HISTORY_CURRENT_PAGE,
    SET_FUNDING_HISTORY_DATA,
    SET_POSITIONS_HISTORY_DATA,
    SET_TRADES_HISTORY_CURRENT_PAGE,
    SET_TRADES_HISTORY_DATA,
} from './slice';

function* handleDepositsWithdrawalsHistoryData(
    action: ReturnType<typeof FETCH_DEPOSITS_AND_WITHDRAWALS_HISTORY_DATA>,
): Generator {
    try {
        const state = action.payload;
        const cache = yield* select(getDepositsWithdrawalsHistoryCache);
        const currentPage = yield* select(getDepositsWithdrawalsHistoryCurrentPage);
        const nextPage = yield* select(getDepositsWithdrawalsHistoryNextPageParams);
        const traderAddress = yield* select(getEthAddress);
        const strategy = yield* select(getSelectedStrategy);
        const statsApiUrl = yield* select(getStatsApiUrl);
        if (traderAddress === undefined || strategy === undefined) {
            getFrontendLogger().logError('Trader address or strategy cannot be undefined');
            return;
        }
        let url = buildUrl(statsApiUrl, { account: traderAddress, strategy: strategy.strategy }, 'strategy_updates', {
            kind: ['0', '1', '2'],
            limit: 5,
        });
        if (state === FetchDataState.NextPage) {
            let params;
            if (nextPage != undefined) {
                params = {
                    kind: ['0', '1', '2'],
                    limit: 5,
                    order: 'desc',
                    epoch: nextPage.epoch!,
                    txOrdinal: nextPage.txOrdinal!,
                    ordinal: nextPage.ordinal!,
                };
            } else {
                params = { kind: ['0', '1', '2'], limit: 5, order: 'desc' };
            }
            url = buildUrl(
                statsApiUrl,
                { account: traderAddress, strategy: strategy.strategy },
                'strategy_updates',
                params,
            );
            const response = yield* call(makeRequest, url);
            const parsedResponse = yield* call(parseStrategyUpdateResponse, response);
            if (nextPage) {
                const data = [...cache, parsedResponse];
                yield* putResolve(SET_DEPOSITS_WITHDRAWALS_HISTORY_DATA(data));
                yield* putResolve(SET_DEPOSITS_WITHDRAWALS_HISTORY_CURRENT_PAGE(currentPage + 1));
            } else {
                yield* putResolve(SET_DEPOSITS_WITHDRAWALS_HISTORY_DATA([parsedResponse]));
                yield* putResolve(SET_DEPOSITS_WITHDRAWALS_HISTORY_CURRENT_PAGE(currentPage + 1));
            }
        }
        if (state === FetchDataState.PrevPage) {
            yield* putResolve(SET_DEPOSITS_WITHDRAWALS_HISTORY_CURRENT_PAGE(currentPage - 1));
        }
    } catch (error: any) {
        getFrontendLogger().logError(
            'caught exception in fetching deposits and withdrawals data',
            getErrorMessage(error),
        );
    }
}

export function* watchDepositsWithdrawalsHistoryData() {
    yield* takeLatest(ActionType.FETCH_DEPOSITS_AND_WITHDRAWALS_DATA, handleDepositsWithdrawalsHistoryData);
}

function* handleDDXRewardsHistoryData(action: ReturnType<typeof FETCH_DDX_REWARDS_HISTORY_DATA>): Generator {
    try {
        const state = action.payload;
        const cache = yield* select(getDDXRewardsHistoryCache);
        const currentPage = yield* select(getDDXRewardsHistoryCurrentPage);
        const nextPage = yield* select(getDDXRewardsHistoryNextPageParams);
        const traderAddress = yield* select(getEthAddress);
        const statsApiUrl = yield* select(getStatsApiUrl);
        if (traderAddress === undefined) {
            getFrontendLogger().logError('Trader address cannot be undefined');
            return;
        }
        let url = buildUrl(statsApiUrl, { account: traderAddress }, 'trader_updates', { kind: 3, limit: 5 });
        if (state === FetchDataState.NextPage) {
            let params;
            if (nextPage != undefined) {
                params = {
                    kind: 3,
                    limit: 5,
                    order: 'desc',
                    epoch: nextPage.epoch!,
                    txOrdinal: nextPage.txOrdinal!,
                    ordinal: nextPage.ordinal!,
                };
            } else {
                params = { kind: 3, limit: 5, order: 'desc' };
            }
            url = buildUrl(statsApiUrl, { account: traderAddress }, 'trader_updates', params);
            const response = yield* call(makeRequest, url);
            const parsedResponse = yield* call(parseDDXRewardsResponse, response);
            if (nextPage) {
                const data = [...cache, parsedResponse];
                yield* putResolve(SET_DDX_REWARDS_HISTORY_DATA(data));
                yield* putResolve(SET_DDX_REWARDS_HISTORY_CURRENT_PAGE(currentPage + 1));
            } else {
                yield* putResolve(SET_DDX_REWARDS_HISTORY_DATA([parsedResponse]));
                yield* putResolve(SET_DDX_REWARDS_HISTORY_CURRENT_PAGE(currentPage + 1));
            }
        }
        if (state === FetchDataState.PrevPage) {
            yield* putResolve(SET_DDX_REWARDS_HISTORY_CURRENT_PAGE(currentPage - 1));
        }
    } catch (error: any) {
        getFrontendLogger().logError('caught exception in fetching ddx rewards data', getErrorMessage(error));
    }
}

export function* watchDDXRewardsHistoryData() {
    yield* takeLatest(ActionType.FETCH_DDX_REWARDS_DATA, handleDDXRewardsHistoryData);
}

function* handleFundingHistoryData(action: ReturnType<typeof FETCH_FUNDING_HISTORY_DATA>): Generator {
    try {
        const state = action.payload;
        const cache = yield* select(getFundingHistoryCache);
        const currentPage = yield* select(getFundingHistoryCurrentPage);
        const nextPage = yield* select(getFundingHistoryNextPageParams);
        const traderAddress = yield* select(getEthAddress);
        const strategy = yield* select(getSelectedStrategy);
        const statsApiUrl = yield* select(getStatsApiUrl);
        if (traderAddress === undefined || strategy === undefined) {
            getFrontendLogger().logError('Trader address or strategy cannot be undefined');
            return;
        }
        let url = buildUrl(statsApiUrl, { account: traderAddress, strategy: strategy.strategy }, 'strategy_updates', {
            kind: 3,
            limit: 5,
            order: 'desc',
        });
        if (state === FetchDataState.NextPage) {
            let params;
            if (nextPage != undefined) {
                params = {
                    kind: 3,
                    limit: 5,
                    order: 'desc',
                    epoch: nextPage.epoch!,
                    txOrdinal: nextPage.txOrdinal!,
                    ordinal: nextPage.ordinal!,
                };
            } else {
                params = { kind: 3, limit: 5, order: 'desc' };
            }
            url = buildUrl(
                statsApiUrl,
                { account: traderAddress, strategy: strategy.strategy },
                'strategy_updates',
                params,
            );
            const response = yield* call(makeRequest, url);
            const parsedResponse = yield* call(parseStrategyUpdateResponse, response);
            if (nextPage) {
                const data = [...cache, parsedResponse];
                yield* putResolve(SET_FUNDING_HISTORY_DATA(data));
                yield* putResolve(SET_FUNDING_HISTORY_CURRENT_PAGE(currentPage + 1));
            } else {
                yield* putResolve(SET_FUNDING_HISTORY_DATA([parsedResponse]));
                yield* putResolve(SET_FUNDING_HISTORY_CURRENT_PAGE(currentPage + 1));
            }
        }
        if (state === FetchDataState.PrevPage) {
            yield* putResolve(SET_FUNDING_HISTORY_CURRENT_PAGE(currentPage - 1));
        }
    } catch (error: any) {
        getFrontendLogger().logError('caught exception in fetching funding data', getErrorMessage(error));
    }
}

export function* watchFundingHistoryData() {
    yield* takeLatest(ActionType.FETCH_FUNDING_HISTORY_DATA, handleFundingHistoryData);
}

export function* handlePositionHistoryDirtyFlag(): Generator {
    try {
        const dirtyFlag = yield* select(getPositionsHistoryDirtyFlag);
        if (!dirtyFlag) {
            return;
        }
        const traderAddress = yield* select(getEthAddress);
        const strategy = yield* select(getSelectedStrategy);
        const statsApiUrl = yield* select(getStatsApiUrl);
        if (traderAddress === undefined || strategy === undefined) {
            getFrontendLogger().logError('Trader address or strategy cannot be undefined');
            return;
        }
        const url = buildUrl(statsApiUrl, { account: traderAddress, strategy: strategy.strategy }, 'positions', {});
        const response = yield* call(makeRequest, url);
        const parsedResponse = yield* call(parsePositionResponse, response);
        if (parsedResponse.success) {
            yield* putResolve(SET_POSITIONS_HISTORY_DATA(parsedResponse));
        }
    } catch (error: any) {
        getFrontendLogger().logError('caught exception in fetching positions data', getErrorMessage(error));
    }
}

export function* watchPositionHistoryDirtyFlag() {
    yield* takeLatest(ActionType.FETCH_POSITIONS_HISTORY_DATA, handlePositionHistoryDirtyFlag);
}

function* handleAdlHistoryData(action: ReturnType<typeof FETCH_ADL_HISTORY_DATA>): Generator {
    try {
        const state = action.payload;
        const cache = yield* select(getAdlHistoryCache);
        const currentPage = yield* select(getAdlHistoryCurrentPage);
        const nextPage = yield* select(getAdlHistoryNextPageParams);
        const filters = yield* select(getAdlHistoryFilters);
        const symbol = filters.symbol === 'all' ? undefined : filters.symbol;
        const traderAddress = yield* select(getEthAddress);
        const strategy = yield* select(getSelectedStrategy);
        const statsApiUrl = yield* select(getStatsApiUrl);
        if (traderAddress === undefined || strategy === undefined) {
            getFrontendLogger().logError('Trader address or strategy cannot be undefined');
            return;
        }
        let url = buildUrl(statsApiUrl, { account: traderAddress, strategy: strategy.strategy }, 'adl', {
            symbol,
        });
        if (state === FetchDataState.NextPage) {
            let params;
            if (nextPage != undefined) {
                params = {
                    limit: 5,
                    symbol,
                    order: 'desc',
                    epoch: nextPage.epoch!,
                    txOrdinal: nextPage.txOrdinal!,
                    ordinal: nextPage.ordinal!,
                };
            } else {
                params = { limit: 5, symbol, order: 'desc' };
            }
            url = buildUrl(statsApiUrl, { account: traderAddress, strategy: strategy.strategy }, 'adl', params);
            const response = yield* call(makeRequest, url);
            const parsedResponse = yield* call(parseAdlResponse, response);
            if (nextPage) {
                const data = [...cache, parsedResponse];
                yield* putResolve(SET_ADL_HISTORY_DATA(data));
                yield* putResolve(SET_ADL_HISTORY_CURRENT_PAGE(currentPage + 1));
            } else {
                yield* putResolve(SET_ADL_HISTORY_DATA([parsedResponse]));
                yield* putResolve(SET_ADL_HISTORY_CURRENT_PAGE(currentPage + 1));
            }
        }
        if (state === FetchDataState.PrevPage) {
            yield* putResolve(SET_ADL_HISTORY_CURRENT_PAGE(currentPage - 1));
        }
    } catch (error: any) {
        getFrontendLogger().logError('caught exception in fetching adl data', getErrorMessage(error));
    }
}

export function* watchAdlHistoryData() {
    yield* takeLatest(ActionType.FETCH_ADL_HISTORY_DATA, handleAdlHistoryData);
}

function* handleTradesHistoryData(action: ReturnType<typeof FETCH_TRADES_HISTORY_DATA>): Generator {
    try {
        // all the data we need to proceed
        const state = action.payload;
        const cache = yield* select(getTradesHistoryCache);
        const currentPage = yield* select(getTradesHistoryCurrentPage);
        const nextPage = yield* select(getTradesHistoryNextPageParams);
        const filters = yield* select(getTradesHistoryFilters);
        const symbol = filters.symbol === 'all' ? undefined : filters.symbol;
        const traderAddress = yield* select(getEthAddress);
        const strategy = yield* select(getSelectedStrategy);
        const statsApiUrl = yield* select(getStatsApiUrl);
        if (traderAddress === undefined || strategy === undefined) {
            getFrontendLogger().logError('Trader address or strategy cannot be undefined');
            return;
        }
        // Generate default url to fetch data from the stats api
        let url = buildUrl(statsApiUrl, { account: traderAddress, strategy: strategy.strategy }, 'trades', {
            symbol,
        });
        // Prepare to fetch next page
        if (state === FetchDataState.NextPage) {
            let params;
            // If there is a next page entry, add the epoch, txOrdinal, ordinal values
            if (nextPage != undefined) {
                params = {
                    limit: 5,
                    symbol,
                    order: 'desc',
                    epoch: nextPage.epoch!,
                    txOrdinal: nextPage.txOrdinal!,
                    ordinal: nextPage.ordinal!,
                };
                // Its the first page fetch
            } else {
                params = { limit: 5, symbol, order: 'desc' };
            }
            // generate url based on the params
            url = buildUrl(statsApiUrl, { account: traderAddress, strategy: strategy.strategy }, 'trades', params);
            // fetch and parse the data
            const response = yield* call(makeRequest, url);
            const parsedResponse = yield* call(parseTradesResponse, response);
            // Prepara the results and add to the state
            const data = [...cache, parsedResponse];
            yield* putResolve(SET_TRADES_HISTORY_DATA(data));
            yield* putResolve(SET_TRADES_HISTORY_CURRENT_PAGE(currentPage + 1));
        }
        // If its Prev Page, just move to previous page in the cached results
        if (state === FetchDataState.PrevPage) {
            yield* putResolve(SET_TRADES_HISTORY_CURRENT_PAGE(currentPage - 1));
        }
    } catch (error: any) {
        getFrontendLogger().logError('caught exception in fetching trades data', getErrorMessage(error));
    }
}

export function* watchTradesHistoryData() {
    yield* takeLatest(ActionType.FETCH_TRADES_HISTORY_DATA, handleTradesHistoryData);
}

function* handleFeesHistoryData(action: ReturnType<typeof FETCH_FEES_HISTORY_DATA>): Generator {
    try {
        const state = action.payload;
        const cache = yield* select(getFeesHistoryCache);
        const currentPage = yield* select(getFeesHistoryCurrentPage);
        const nextPage = yield* select(getFeesHistoryNextPageParams);
        const filters = yield* select(getFeesHistoryFilters);
        const symbol = filters.symbol === 'all' ? undefined : filters.symbol;
        const traderAddress = yield* select(getEthAddress);
        const strategy = yield* select(getSelectedStrategy);
        const statsApiUrl = yield* select(getStatsApiUrl);
        if (traderAddress === undefined || strategy === undefined) {
            getFrontendLogger().logError('Trader address or strategy cannot be undefined');
            return;
        }
        let url = buildUrl(statsApiUrl, { account: traderAddress, strategy: strategy.strategy }, 'fees', {
            limit: 5,
            symbol,
        });
        if (state === FetchDataState.NextPage) {
            let params;
            if (nextPage != undefined) {
                params = {
                    limit: 5,
                    symbol,
                    order: 'desc',
                    epoch: nextPage.epoch!,
                    txOrdinal: nextPage.txOrdinal!,
                    ordinal: nextPage.ordinal!,
                };
            } else {
                params = { limit: 5, symbol, order: 'desc' };
            }
            url = buildUrl(statsApiUrl, { account: traderAddress, strategy: strategy.strategy }, 'fees', params);
            const response = yield* call(makeRequest, url);
            const parsedResponse = yield* call(parseFeeResponse, response);
            if (nextPage) {
                const data = [...cache, parsedResponse];
                yield* putResolve(SET_FEES_HISTORY_DATA(data));
                yield* putResolve(SET_FEES_HISTORY_CURRENT_PAGE(currentPage + 1));
            } else {
                yield* putResolve(SET_FEES_HISTORY_DATA([parsedResponse]));
                yield* putResolve(SET_FEES_HISTORY_CURRENT_PAGE(currentPage + 1));
            }
        }
        if (state === FetchDataState.PrevPage) {
            yield* putResolve(SET_FEES_HISTORY_CURRENT_PAGE(currentPage - 1));
        }
    } catch (error: any) {
        getFrontendLogger().logError('caught exception in fetching fees data', getErrorMessage(error));
    }
}

export function* watchFeesHistoryData() {
    yield* takeLatest(ActionType.FETCH_FEE_HISTORY_DATA, handleFeesHistoryData);
}

/**
 * The portfolio saga layer is responsable for handling side effects for the portfolio dashboard
 */
export const portfolioTablesSaga = function* root() {
    yield* all([
        fork(watchDepositsWithdrawalsHistoryData),
        fork(watchDDXRewardsHistoryData),
        fork(watchFundingHistoryData),
        fork(watchPositionHistoryDirtyFlag),
        fork(watchAdlHistoryData),
        fork(watchTradesHistoryData),
        fork(watchFeesHistoryData),
    ]);
};
