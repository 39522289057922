import {
    ActionType,
    AggregationPeriod,
    Ordering,
    PaginatedTopTradersResponse,
    TopTradersOrdering,
    TopTradersResponseItem,
} from '@derivadex/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FetchLeaderboardDataParams } from 'store/requestUtils';
import { createAction } from 'typesafe-actions';

export type LeaderboardState = {
    aggregationPeriod: AggregationPeriod;
    orderBy: TopTradersOrdering;
    order: Ordering;
    loggedInTraderData: TopTradersResponseItem | null;
    leaderboardData: {
        cache: TopTradersResponseItem[][];
        nextCursor: number | null;
        currentPage: number;
    };
    initialLoadFlag: boolean;
};

export const initialLeaderboardState: LeaderboardState = {
    aggregationPeriod: AggregationPeriod.Day,
    orderBy: 'volume',
    order: 'desc',
    loggedInTraderData: null,
    leaderboardData: { cache: [], nextCursor: null, currentPage: 0 },
    initialLoadFlag: true,
};

export const leaderboardSlice = createSlice({
    name: 'leaderboard',
    initialState: initialLeaderboardState,
    reducers: {
        SET_LEADERBOARD_DATA: (
            state,
            action: PayloadAction<{
                paginatedTopTradersResponse: PaginatedTopTradersResponse;
                shouldClearCache: boolean;
            }>,
        ) => {
            if (action.payload.shouldClearCache) {
                state.leaderboardData.cache = [];
            }
            state.leaderboardData.cache.push(action.payload.paginatedTopTradersResponse.traders);
            state.leaderboardData.nextCursor = action.payload.paginatedTopTradersResponse.nextCursor;
        },
        SET_TRADER_LEADERBOARD_DATA: (
            state,
            action: PayloadAction<{
                traderData: TopTradersResponseItem | null;
            }>,
        ) => {
            state.loggedInTraderData = action.payload.traderData;
        },
        SET_LEADERBOARD_ORDERING: (
            state,
            action: PayloadAction<{ tradersOrdering: TopTradersOrdering; order: Ordering }>,
        ) => {
            state.orderBy = action.payload.tradersOrdering;
            state.order = action.payload.order;
        },
        SET_LEADERBOARD_CURRENT_PAGE: (state, action: PayloadAction<number>) => {
            state.leaderboardData.currentPage = action.payload;
            if (state.initialLoadFlag) {
                state.initialLoadFlag = false;
            }
        },
        RESET_LEADERBOARD_PAGE: (state) => {
            state.aggregationPeriod = AggregationPeriod.Day;
            state.orderBy = 'volume';
            state.order = 'desc';
            state.loggedInTraderData = null;
            state.leaderboardData = { cache: [], nextCursor: null, currentPage: 0 };
            state.initialLoadFlag = true;
        },
    },
});

export const {
    SET_LEADERBOARD_DATA,
    SET_TRADER_LEADERBOARD_DATA,
    SET_LEADERBOARD_CURRENT_PAGE,
    SET_LEADERBOARD_ORDERING,
    RESET_LEADERBOARD_PAGE,
} = leaderboardSlice.actions;

export const FETCH_LEADERBOARD_DATA = createAction(ActionType.FETCH_LEADERBOARD_DATA)<FetchLeaderboardDataParams>();
