import { BigNumber } from '@0x/utils';
import { Flex, Text, useTheme } from '@chakra-ui/react';
import { getFrontendLogger } from '@derivadex/utils';
import { createColumnHelper } from '@tanstack/react-table';
import CryptoIcon from 'components/CryptoIcon/CryptoIcon';
import ExplorerButton from 'components/Marketplace/OrderTables/ExplorerButton';
import NumberFormatMinimumValue from 'components/Marketplace/OrderTables/NumberFormatMinimumValue';
import Table from 'components/Table/Table';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';

export default function FeesTable({
    isDesktopView,
    data,
    paginationData,
}: {
    isDesktopView: boolean;
    data: any[];
    paginationData?: {
        isEnabled: boolean;
        currentPage: number;
        prevPage?: () => void;
        nextPage?: () => void;
        limitPerPage?: number;
    };
}) {
    const { t } = useTranslation();
    const { colors } = useTheme();

    const columnHelper = createColumnHelper<any>();
    const columns = [
        columnHelper.accessor('symbol', {
            cell: (info) => (
                <Flex align="center">
                    <CryptoIcon currency={getCryptoIcon(info.getValue())} size={4} />
                    <Text ml="0.5rem" fontWeight="600">
                        {`${info.getValue()}`}
                    </Text>
                </Flex>
            ),
            header: t('symbol'),
            meta: {
                style: !isDesktopView
                    ? {
                          position: 'sticky',
                          left: 0,
                          zIndex: 10,
                          backgroundColor: `${colors.card[100]}`,
                      }
                    : null,
            },
        }),
        columnHelper.accessor('createdAt', {
            cell: (info) => format(new Date(info.getValue()), 'MM/dd/yy, pp'),
            header: t('date'),
        }),
        columnHelper.accessor('amount', {
            cell: (info) => <NumberFormatMinimumValue value={new BigNumber(info.getValue())} />,
            header: t('amount'),
            meta: {
                isNumeric: true,
            },
        }),
        columnHelper.accessor('feeSymbol', {
            cell: (info) => (
                <Text ml="0.5rem" fontWeight="600">
                    {`${info.getValue()}`}
                </Text>
            ),
            header: 'Fee Symbol',
        }),
        columnHelper.accessor((row) => row, {
            cell: (info) => (
                <>
                    <Flex>
                        <Text>{`Epoch ${info.getValue().epochId}, TxOrdinal ${info.getValue().txOrdinal} `}</Text>
                        <ExplorerButton
                            label={t('paymentsHistoryExplorerButtonDescription')}
                            url={`epoch/${info.getValue().epochId}/txOrdinal/${info.getValue().txOrdinal}`}
                        />
                    </Flex>
                </>
            ),
            header: 'Transaction',
        }),
    ];

    function getCryptoIcon(symbol: string | undefined) {
        if (symbol === 'BTCP') return 'BTC';
        else return 'ETH';
    }

    return (
        <div className="table-fees-history">
            <Table
                isDesktopView={isDesktopView}
                columns={columns}
                data={data}
                emptyText={t('noData')}
                sortBy={[{ id: 'createdAt', desc: true }]}
                paginationData={paginationData}
            />
        </div>
    );
}
