import { Box } from '@chakra-ui/react';
import { StatsAPISuccessTimeseriesResponse, StrategyUpdateResponseItem } from '@derivadex/types';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFundingHistoryCurrentPage, getFundingHistoryData } from 'store/portfolio/selectors';
import { FETCH_FUNDING_HISTORY_DATA } from 'store/portfolio/slice';
import { FetchDataState, PortfolioTableType } from 'store/requestUtils';
import { getSelectedStrategy } from 'store/strategy/selectors';
import { getEthAddress } from 'store/web3/selectors';

import TableHeader from '../Header/TableHeader';
import FundingHistoryTable from './FundingHistoryTable';

export default function FundingHistory({ isDesktopView }: { isDesktopView: boolean }) {
    const dispatch = useDispatch();
    const currentStrategy = useSelector(getSelectedStrategy);
    const traderAddress = useSelector(getEthAddress);
    const data: StatsAPISuccessTimeseriesResponse<StrategyUpdateResponseItem> | undefined =
        useSelector(getFundingHistoryData);
    const currentPage: number = useSelector(getFundingHistoryCurrentPage);

    useEffect(() => {
        if (!currentStrategy || !traderAddress || data !== undefined || currentPage !== 0) {
            return;
        }
        dispatch(FETCH_FUNDING_HISTORY_DATA(FetchDataState.NextPage));
    }, [currentStrategy, traderAddress]);

    const nextPage = () => {
        dispatch(FETCH_FUNDING_HISTORY_DATA(FetchDataState.NextPage));
    };

    const prevPage = () => {
        dispatch(FETCH_FUNDING_HISTORY_DATA(FetchDataState.PrevPage));
    };

    return (
        <Box p="0.5rem">
            <TableHeader portfolioTable={PortfolioTableType.FundingHistory} />
            <FundingHistoryTable
                isDesktopView={true}
                data={data ? data.value : []}
                paginationData={{ isEnabled: true, currentPage, nextPage, prevPage, limitPerPage: 5 }}
            ></FundingHistoryTable>
        </Box>
    );
}
